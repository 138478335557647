import { OnInit, HostListener } from "@angular/core";
import { Component } from "@angular/core";
import { Router } from '@angular/router';
import { AccountService } from "../../services/account.service";
import { IAccount } from "../../models/IAccount";
import { AccountInfoAttrEnum } from "../../models/AccountInfoAttrEnum";
import { Subject, Observable, interval } from "rxjs";
import { throttle } from "rxjs/operators";
import { PayComponent } from "../pay/pay.component";
import { MatDialog } from "@angular/material";
import {UtilsService} from "../../services/utils.service";

@Component({
    selector: 'app-list-dogovor-main',
    templateUrl: 'list-dogovor-main.component.html',
    styleUrls: ['./list-dogovor-main.component.css']
})
/** Контроллер списка договоров */
export class ListDogovorsMainComponent implements OnInit {

    /** Список ЛС для отображения */
    public tableAcc: IAccount[];

    /** Выбранная строка */
    public selectedRow: IAccount;

    /** Массив флагов наведения машки на оплату */
    public mouseOverSum: number[];

    /** Количество договоров */
    public docCount: number;

    /** Строка поиска */
    public searchTerm: string = "";

    public showDetailMode: boolean = true;

    /** Последняя ошибка запроса к серверу */
    public error: string;

    /**
     * Режим экрана
     * 1 - 1920х1080
     * 2 - 1024х768
     * 3 - 640х360
     */
    public screenMode: number = 1;

    /** Список всех лицевых счетов */
    private allAcc: IAccount[];

    /** для throttle событий изменения экрана */
    private windowChanged: Subject<void> = new Subject<void>();
    private windowChangeThrottled: Observable<void>;

    /**
     * Конструктор
     * @param router Модуль маршрутизации
     * @param accountService Сервис лицевых счетов
     */
    constructor(
        private router: Router,
        private accountService: AccountService,
        public dialog: MatDialog) {
    }

    /** При созднаии компонента */
    ngOnInit(): void {
        this.windowChangeThrottled = this.windowChanged.pipe(throttle(val => interval(0)));
        this.windowChangeThrottled.subscribe(() => {
            this.handleResize();
        });
        this.handleResize();
        this.mouseOverSum = [];
        this.tableAcc = null;
        this.accountService.get(AccountInfoAttrEnum.NextPay | AccountInfoAttrEnum.Debt).subscribe(
            (accs) => {
                this.allAcc = accs;
                this.tableAcc = this.allAcc.sort((a, b) => new Date(b.DogovorDate).getTime() - new Date(a.DogovorDate).getTime()).slice(0, 8);
                this.docCount = this.allAcc.length;
                this.error = null;
            },
            (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
            });
    }

    /** Событие изменения размера экрана */
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.windowChanged.next();

    }

    /** Событие выбора строки таблицы */
    public setClickedRow = function (item) {
        this.selectedRow = item;
    }

    /** Функция поиска */
    public search = function () {
        this.tableAcc = Object.assign([], this.allAcc).filter(
            item => item.DogovorNumber.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1 ||
                item.AccountNumber.toLowerCase().indexOf(this.searchTerm.toLowerCase()) > -1
        ).slice(0, 8);
    }

    /** Переход к договорам */
    public toListDogovorClick() {
        this.router.navigateByUrl('/list-dogovors');
    }


    /** Открыть компонент оплаты */
    public onPayNextClick(selectedAccount: IAccount) {
        const dialogRef = this.dialog.open<PayComponent>(PayComponent, {
            panelClass: 'app-dialog-container',
            data: {
                account: selectedAccount,
                sumToPay: selectedAccount.NextPaySum
            }
        });
    }

    /**
     * Переход к договору
     * @param selectedAccount Выбарнный лицевой счет
     */
    public toDogovorClick(selectedAccount: IAccount) {
        if (selectedAccount != this.selectedRow)
            return;

        window.open("/dogovor-info?dogId=" + selectedAccount.DogovorId);

    }

    /** Обработать изменение экрана */
    private handleResize(): void {
        if (window.innerWidth <= 900 && this.screenMode !== 3) {
            this.screenMode = 3;
            this.showDetailMode = true;
        } else if (window.innerWidth > 900 && window.innerWidth <= 1224 && this.screenMode !== 2) {
            this.screenMode = 2;
            this.showDetailMode = true;
        } else if (window.innerWidth > 1224 && this.screenMode !== 1) {
            this.screenMode = 1;
            this.showDetailMode = true;
        }
    }

    /** Клик по показать инфо в режиме мобильного экрана */
    public clickShowDetail() {
        this.showDetailMode = false;
    }

    /** Клик по обратно в список в режиме мобильного экрана */
    public clickHideDetail() {
        this.showDetailMode = true;
    }

}