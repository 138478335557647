import { Component, OnInit } from '@angular/core';
import { ITenant } from '../../models/ITenant';
import { TenantService } from '../../services/tenant.service';

@Component({
    selector: 'app-tenant',
    templateUrl: 'tenant.component.html',
    styleUrls: ['./tenant.component.css']
})
/** Контроллер арендатора */
export class TenantComponent implements OnInit {

    /** Арендатор */
    public tenant: ITenant;

    /**
     * Конструктор
     * @param tenantService Сервис работы с арендатором
     */
    constructor(
        private tenantService: TenantService) {
    }

    /**
     * Действия выполняемые при создании объекта
     */
    public ngOnInit() {
        this.tenantService.get().subscribe(
            (tenant) => {
                this.tenant = tenant;
            },
            (error) => {
                console.log(error);
            });
    }
}
