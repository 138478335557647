import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IAccount } from '../../models/IAccount';
import { ICalcDoc } from '../../models/ICalcDoc';
import { IDogovor } from '../../models/IDogvor';
import { AccountService } from '../../services/account.service';
import { CalcDocService } from '../../services/calc-doc.service';
import { DogovorService } from '../../services/dogovor.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-dogovor-info',
    templateUrl: 'dogovor-info.component.html',
    styleUrls: ['./dogovor-info.component.css']
})
/** Контроллер краткой информации по договору */
export class DogovorInfoComponent implements OnInit {
    /** Документы основания */
    public calcDocs: ICalcDoc[];
    /** Лицевые счета */
    public accounts: IAccount[];
    /** Договор */
    public dogovor: IDogovor;
    /** Признак процесса загрузки договора */
    public isLoadDogovor: boolean = true;
    /** Ошибка по документам-основаниям */
    public calcDocsError: string;
    /** Ошибка по лицевым счетам */
    public accountsError: string;
    /** Ошибка по договорам */
    public dogovorError: string;
    /** Идентификиатор договора */
    private dogId: string;

    /**
     * Конструктор
     * @param router Модуль маршрутизации
     * @param accountService Сервис лицевых счетов
     * @param calcDocService Сервис документов оснований
     * @param dogovorService Сервис договоров
     */
    constructor(
        protected route: ActivatedRoute,
        private accountService: AccountService,
        private calcDocService: CalcDocService,
        private dogovorService: DogovorService) {
    }

    public ngOnInit(): void {
        this.dogId = this.route.snapshot.queryParams.dogId;
        this.calcDocService.get(this.dogId, false).subscribe(
            (calcDocs) => {
                this.calcDocs = calcDocs.sort((a, b) =>
                    new Date(b.DateStart).getTime() - new Date(a.DateStart).getTime());
                this.calcDocsError = null;
            },
            (error) => {
                console.log(error);
                this.calcDocsError = UtilsService.getErrorText(error);
            }
        );
        this.accountService.getAccountListByDog(this.dogId).subscribe(
            (accounts) => {
                this.accounts = accounts;
                this.accountsError = null;
            },
            (error) => {
                console.log(error);
                this.accountsError = UtilsService.getErrorText(error);
            }
        );
        this.dogovorService.get(this.dogId).subscribe(
            (dogovor) => {
                this.dogovor = dogovor;
                this.dogovorError = null;
                this.isLoadDogovor = false;
            },
            (error) => {
                console.log(error);
                this.dogovorError = UtilsService.getErrorText(error);
                this.isLoadDogovor = false;
            }
        );
    }
}
