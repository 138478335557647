import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-password-recovery',
    templateUrl: 'password-recovery.component.html',
    styleUrls: ['./password-recovery.component.css']
})
/** Контроллер формы восстановления пароля */
export class PasswordRecoveryComponent implements OnInit {

    /** Введённое значение логина */
    public loginValue;

    /** Введённое значение пароля */
    public emailValue;

    /** Флаг что поля ввода не валидные */
    public fieldsNotValid: boolean = true;

    /** Флаг выполнения запроса входа */
    public loading = false;

    /** Сообщение об ошибке */
    public errorMessage: string;

    /** Сообщение об ошибке */
    public hintMessage: string = 'На этот E-mail будет отправлено письмо с временным паролем';

    /**
     * Конструктор
     * @param router Модуль маршрутизации
     * @param authenticationService Сервис аутентификации пользователя
     */
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) {
    }

    // tslint:disable-next-line:no-empty
    public ngOnInit(): void {}

    /**
     * Проверить поля ввода на валидность
     */
    public validateFields() {
        this.fieldsNotValid = !this.loginValue || !this.emailValue;
    }

    /** Обработчик нажатия кнопки входа */
    public onSubmit() {
        this.loading = true;
        this.errorMessage = '';

        this.authenticationService.restorePassword(this.loginValue, this.emailValue).subscribe(
            (result) => {
                this.loading = false;
                this.hintMessage = 'Пароль успешно отправлен';
            },
            (error) => {
                this.loading = false;
                this.errorMessage = error.error.ExceptionMessage ? error.error.ExceptionMessage : error.message;
            });
    }
}
