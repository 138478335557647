import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IDogovor } from '../models/IDogvor';

/** Сервис для работы с договорами */
@Injectable()
export class DogovorService {

    /**
     * Базовый URL для API
     */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Клиент для работы с http
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl + 'api/dogovor';
    }

    /**
     * Получение договора
     * @param dogovorId Идентификатор договора
     */
    public get(dogovorId: string): Observable<IDogovor> {
        return this.http.get<IDogovor>(this.apiUrl, {
            params: { dogId: dogovorId }
        });
    }
}
