import {OnInit} from "@angular/core";
import {Component} from "@angular/core";
import { MatDialog } from "@angular/material";
import { ReviseActComponent } from "../revise-act/revise-act.component";
import { Route } from "@angular/compiler/src/core";
import { ActivatedRoute } from "@angular/router";
import { PayWaitComponent } from "../pay-wait/pay-wait.component";

@Component({
    selector: 'app-main',
    templateUrl: 'main.component.html',
    styleUrls: ['./main.component.css']
})
/** Главная страница */
export class MainComponent implements OnInit {
    /**
     * Конструктор
     * @param referenceService Сервис справочников
     * @param dialog Диалоговые окна
     * @param alertService сообщения об ошибках
     */
    constructor(
        public dialog: MatDialog,
        public route: ActivatedRoute
    ) { }

    public ngOnInit(): void {
        const orderId = +this.route.snapshot.queryParamMap.get("Order_ID");
        if (orderId) {
            const dialogRef = this.dialog.open<PayWaitComponent>(PayWaitComponent, {
                panelClass: 'app-dialog-container', 
                data: {
                    orderId: orderId
                }
            });
        }
    }
}
