import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IAccount } from '../models/IAccount';
import { IDebt } from '../models/IDebt';

/** Сервис для работы с оплатами */
@Injectable()
export class PayService {

    /**
     * Базовый URL для API
     */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Клиент для работы с http
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl + 'api/pay';
    }

    /**
     * Создание платежа по карте, возвразается URL с переходом
     */
    public createPayByCard(debts: IDebt[]): Observable<string> {
        return this.http.post<string>(this.apiUrl + '/createPayByCard', debts);
    }

    /**
     * Получение статуса платежа по номеру заказа
     * @param orderId Номер заказа
     */
    public getStatusByOrderId(orderId: string): Observable<string> {
        return this.http.get<string>(this.apiUrl + '/getPayStatusByOrderId', {
            params: { orderId: orderId }
        });
    }

    /**
     * Проверяем, есть ли возможность оплатить по карте.
     */
    public createPayByCardCanExecute(): Observable<boolean> {
        return this.http.get<boolean>(this.apiUrl + '/checkPayService');
    }
}
