import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

/** Сервис для работы с ЕСИА */
@Injectable()
export class EsiaService {

    /**
     * Базовый URL для API
     */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Клиент для работы с http
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl + 'api/esia';
    }

    /**
     * Проверка есть ли ЕСИА
     */
    public getIsHaveEsia(): Observable<boolean> {
        return this.http.get<boolean>(this.apiUrl + '/isHave');
    }

    /**
     * Получение ссылки переадресации
     */
    public getRedirect(): Observable<string> {
        return this.http.get<string>(this.apiUrl + '/getEsiaRedirect');
    }

    /**
     * Получение токена по ЕСИА
     * @param code Код доступа
     * @param status Статус
     */
    public getToken(code: string, state: string): Observable<String> {
        return this.http.get<string>(this.apiUrl + '/getToken', {
            params: { code: code, state: state }
        });
    }
}
