import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { EsiaService } from '../../services/esia.service';

@Component({
    selector: 'app-login',
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css']
})
/** Контроллер формы входа */
export class LoginComponent implements OnInit {
    /** Форма входа */
    public loginForm: FormGroup;
    /** Флаг выполнения запроса входа */
    public loading = false;
    /** Флаг подтверждения данных формы */
    public submitted = false;
    /** Сообщение об ошибке */
    public errorMessage: string;
    /** Нажата галка "запомнить меня" */
    public rememberMeMode: boolean;
    /** Флаг возможности входа по ЕСИА */
    public isHaveEsia = false;
    /** Нажата кнопка просмотра пароля */
    public isShowPasswordPressed = false;
    /** Адрес перехода при успешном входе */
    private returnUrl: string;

    /**
     * Конструктор
     * @param formBuilder Построитель элементов формы
     * @param route Объект хранящий информацию о запросе
     * @param router Модуль маршрутизации
     * @param authenticationService Сервис аутентификации пользователя
     * @param activatedRoute Сервис маршуртизации по ссылке
     */
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private esiaService: EsiaService,
        private activatedRoute: ActivatedRoute) {
    }

    /** Свойсто для упрощения доступа к элементам формы */
    get form() {
        return this.loginForm.controls;
    }

    /** Действия выполняемые при создании объекта,
     * инициализация полей формы и адреса перехода
     */
    public ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            isRememberUser: []
        });
        this.authenticationService.logout();
        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

        this.esiaService.getIsHaveEsia().subscribe(
        (result) => {
            this.isHaveEsia = result;
        },
        (error) => {
            this.loading = false;
            this.errorMessage = error.error.ExceptionMessage ? error.error.ExceptionMessage : error.message;
        });

        this.activatedRoute.queryParams.subscribe(params => {
            const code = params['code'];
            const state = params['state'];
            if (code && state){
                this.loading = true;
                this.authenticationService.loginByEsia(code, state,  this.form.isRememberUser.value).subscribe(
                    (result) => {
                        this.router.navigate([this.returnUrl]);
                    },
                    (error) => {
                        this.loading = false;
                        this.errorMessage = error.error.ExceptionMessage ? error.error.ExceptionMessage : error.message;
                    });
            }
        });
    }

    /** Сменить тип поля ввода пароля */
    public changePasswordType(input: any) {
        input.type = input.type === 'password' ? 'text' : 'password';
        this.isShowPasswordPressed = !this.isShowPasswordPressed;
    }

    /** Обработчик нажатия кнопки входа */
    public onSubmit() {
        this.errorMessage = null;
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.loading = true;
        this.authenticationService.login(this.form.username.value,
            this.form.password.value,
            this.form.isRememberUser.value).subscribe(
            (result) => {
                this.router.navigate([this.returnUrl]);
            },
            (error) => {
                this.loading = false;
                this.errorMessage = error.error.ExceptionMessage ? error.error.ExceptionMessage : error.message;
            });
    }

    /**
     * Вход через ЕСИА
     */
    public esiaEnter() {
        this.esiaService.getRedirect().subscribe(
            (result) => {
                window.location.href = result;
            },
            (error) => {
                this.loading = false;
                this.errorMessage = error.error.ExceptionMessage ? error.error.ExceptionMessage : error.message;
            });
    }
}
