import { Component, OnInit } from '@angular/core';
import { AccountInfoAttrEnum } from '../../models/AccountInfoAttrEnum';
import { IAccount } from '../../models/IAccount';
import { AccountService } from '../../services/account.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-debt',
    templateUrl: 'debt.component.html',
    styleUrls: ['./debt.component.css']
})
/** Контроллер блока задолженности */
export class DebtComponent implements OnInit {

    /** Отображаемые ЛС */
    public tableAcc: IAccount[];
    /** Общая задолжность */
    public debtSumAll;
    /** Текущфая дата */
    public now: Date = new Date();
    /** Последняя ошибка запроса к серверу */
    public error: string;
    /** Все ЛС */
    private allAcc: IAccount[];

    /**
     * Конструктор
     * @param accountService Сервис работы с лицевыми счетами
     */
    constructor(
        private accountService: AccountService) {
    }

    /**
     * Действия выполняемые при создании объекта
     */
    public ngOnInit() {
        this.accountService.get(AccountInfoAttrEnum.Debt).subscribe(
            (accs) => {
                this.allAcc = accs;
                this.tableAcc = this.allAcc.sort((x, y) => y.Debt - x.Debt).slice(0, 3);
                this.debtSumAll = this.allAcc.reduce((sum, current) => sum + (current.Debt > 0 ? current.Debt : 0), 0);
                this.error = null;
            },
            (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
            });
    }
}
