import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelper } from 'angular2-jwt';

/** Класс для проверки аутентификации пользователя */
@Injectable()
export class AuthGuardService implements CanActivate {
    /**
     * Конструктор
     * @param router Роутер
     */
    constructor(
        private router: Router
    ) { }

    /**
     * Проверка доступа
     * @param route Маршрут
     * @param state Состояние
     */
    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const localStorageToken = localStorage.getItem('token');
        const sessionStorageToken = sessionStorage.getItem('token');
        const token = localStorageToken !== null ? localStorageToken : sessionStorageToken;

        let result = false;
        if (token) {
            const jwtHelper = new JwtHelper();
            // Дата устанавливается в BalanaceHolderApi (10 дней)
            const tokenExpirationDate = jwtHelper.getTokenExpirationDate(token);
            result = tokenExpirationDate > new Date();
        }
        if (result) {
            return true;
        } else {
            this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
