import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Md5 } from 'ts-md5';
import { environment } from '../../environments/environment';
import { ITenantField } from '../models/ITenantField';

/** Сервис аутентификации */
@Injectable()
export class AuthenticationService {
    /** Для сообщения слушателям о логине/разлогине */
    private loggedIn = new BehaviorSubject<boolean>(
        (!!localStorage.getItem('token')) || (!!sessionStorage.getItem('token')));

    /** Адрес api-сервера */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Сервис для http запросов
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl;
    }

    /**
     * Логин
     * @param username Логин пользователя
     * @param password Пароль
     */
    public login(username: string, password: string, isRememberUser: boolean) {
        const passwordMd5 = Md5.hashStr(password);

        return this.http.post<string>(this.apiUrl + 'api/token', { login: username, password: passwordMd5 })
            .pipe(tap((token) => {
                if (token === 'need_registration') {
                    localStorage.setItem('tempUsername', username);
                    localStorage.setItem('tempPasswordMd5', passwordMd5 + '');
                    window.location.href = '/registration';
                } else if (token) {
                    sessionStorage.setItem('token', token);
                    if (isRememberUser) {
                        localStorage.setItem('token', token);
                    } else {
                        localStorage.removeItem('token');
                    }

                    this.loggedIn.next(true);
                }
            }));
    }

    /**
     * Логин по ЕСИА
     * @param code Код авторизации ЕСИА
     * @param state Идентификатор сессии
     * @param isRememberUser Признак запомнить пользователя
     */
    public loginByEsia(code: string, state: string, isRememberUser: boolean) {
        return this.http.get<string>(this.apiUrl + 'api/esia/getToken', { params: { code: code, state: state } })
            .pipe(tap((token) => {
                if (token === 'need_registration') {
                    window.location.href = '/registration';
                } else if (token) {
                    sessionStorage.setItem('token', token);
                    if (isRememberUser) {
                        localStorage.setItem('token', token);
                    } else {
                        localStorage.removeItem('token');
                    }

                    this.loggedIn.next(true);
                }
            }));
    }

    /** Выход */
    public logout() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        this.loggedIn.next(false);
    }

    /** Признак аутентификации пользователя */
    public isLoggedIn(): Observable<boolean> {
        return this.loggedIn.asObservable();
    }

    /**
     * Получить поля, участвующие в регистрации
     * @returns Список полей для регистрации
     */
    public getFieldsForRegistration(): Observable<ITenantField[]> {
        const username = localStorage.getItem('tempUsername');
        const passwordMd5 = localStorage.getItem('tempPasswordMd5');
        return this.http.post<ITenantField[]>(this.apiUrl +
            'api/registration/getfields', { login: username, password: passwordMd5 });
    }

    /**
     * Отправить запрос регистрации
     * @param newLogin Новый постоянный логин
     * @param newPassword Новый постоянный пароль
     * @param fields Список заполненных полей
     * @returns {Observable<A>}
     */
    public registration(newLogin: string, newPassword: string, fields: ITenantField[]) {
        const tempUsername = localStorage.getItem('tempUsername');
        const tempPasswordMd5 = localStorage.getItem('tempPasswordMd5');

        const newPasswordMd5 = Md5.hashStr(newPassword);

        return this.http.post<string>(this.apiUrl + 'api/registration', {
            tempUsername, tempPasswordMd5,
            newLogin, newPassword, newPasswordMd5, fields
        })
            .pipe(tap((token) => {
                if (token.startsWith('Ошибка')) {
                    throw token;
                }
                if (token) {
                    sessionStorage.setItem('token', token);
                    localStorage.removeItem('token');

                    this.loggedIn.next(true);
                }
            }));
    }

    /**
     * Восстановление пароля
     * @param login Логин
     * @param email Почта
     */
    public restorePassword(login: string, email: string) {
        return this.http.post<string>(this.apiUrl + 'api/registration/restore', { login, email });
    }
}
