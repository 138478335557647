import { Pipe, PipeTransform } from '@angular/core';
import * as dateFormat from 'dateformat';

/**
 * Форматирование для дат арендатора
 */
@Pipe({
  name: 'bhdate'
})
export class BhdatePipe implements PipeTransform {
  public transform(value: any, format: string = 'dd.mm.yyyy HH:MM:ss') {
    return value ? dateFormat(value, format) : '';
  }
}
