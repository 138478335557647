/** Проект */

import { routing } from './app.routing';

import { LOCALE_ID } from '@angular/core';
import { AppComponent } from './app.component';
import { JwtInterceptorService } from './services/jwt-interceptor.service';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthenticationService } from './services/authentication.service';
import { ReportService } from './services/report.service';
import { TenantService } from './services/tenant.service';
import { AccountService } from './services/account.service';
import { ObjectService } from './services/object.service';
import { DogovorTypeService } from './services/dogovor-type.service'
import { CalcDocService } from './services/calc-doc.service'
import { DogovorService } from './services/dogovor.service'
import {PayService} from './services/pay.service'
import { LoginComponent } from './components/login/login.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { TenantComponent } from "./components/tenant/tenant.component";
import { DebtComponent } from "./components/debt/debt.component";
import { MobileVersionComponent } from "./components/mobile-version/mobile-version.component";
import { ListDogovorsMainComponent } from "./components/list-dogovor-main/list-dogovor-main.component";
import {ListDocumentsComponent} from "./components/list-documents/list-documents.component";
import { DogovorShortComponent } from "./components/dogovor-short/dogovor-short.component";
import { LastPayListComponent } from "./components/last-pay-list/last-pay-list.component";
import { LastPayInfoComponent } from "./components/last-pay-info/last-pay-info.component";
import { MainComponent } from './components/main/main.component';
import { ListDogovorsComponent } from './components/list-dogovors/list-dogovors.component';
import { ReviseActComponent } from './components/revise-act/revise-act.component';
import { DogovorInfoComponent } from "./components/dogovor-info/dogovor-info.component";
import { AccountComponent } from "./components/account/account.component";
import { PayComponent } from "./components/pay/pay.component"
import { PayWaitComponent } from "./components/pay-wait/pay-wait.component"
import {TenantDetailComponent} from './components/tenant/tenant-detail.component';
import { EsiaService } from './services/esia.service';
import { PayByCardAttrReqComponent } from './components/pay-by-card-attr-req/pay-by-card-attr-req.component';

import { DummyComponent } from './dummy.component';

import {AgGridCustomTooltip} from "./components/ag-grid-custom-tooltip.component";

/** Внешние */
import { CdkTableModule } from '@angular/cdk/table';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BhdatePipe } from './pipes/bhdate.pipe';
import { DeclensionPipe } from './pipes/declension.pipe';

import { DxFilterBuilderModule } from 'devextreme-angular';
import { InputMaskDirective } from './directives/input-mask-directive';

import { AgGridModule } from 'ag-grid-angular';
import { NgxMaskModule } from 'ngx-mask';
import {registerLocaleData, CurrencyPipe} from '@angular/common';
import localeRu from '@angular/common/locales/ru';
registerLocaleData(localeRu);

import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorIntl,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule
} from '@angular/material';

@NgModule({
    imports: [
        CdkTableModule,
        BrowserModule,
        ReactiveFormsModule,
        HttpClientModule,
        routing,
        FormsModule,

        MatListModule,
        MatInputModule,
        MatToolbarModule,
        MatSidenavModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatStepperModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,

        NoopAnimationsModule,
        NgbModule.forRoot(),

        DxFilterBuilderModule,

        AgGridModule.withComponents([AgGridCustomTooltip]),
        NgxMaskModule.forRoot()
    ],
    declarations: [
        AppComponent,
        BhdatePipe,
        DeclensionPipe,
        DummyComponent,
        LoginComponent,
        PasswordRecoveryComponent,
        RegistrationComponent,
        MainComponent,
        ListDogovorsComponent,
        ListDocumentsComponent,
        InputMaskDirective,
        TenantComponent,
        DebtComponent,
        MobileVersionComponent,
        ListDogovorsMainComponent,
        DogovorShortComponent,
        LastPayListComponent,
        LastPayInfoComponent,
        ReviseActComponent,
        DogovorInfoComponent,
        AccountComponent,
        AgGridCustomTooltip,
        PayComponent,
        PayWaitComponent,
        TenantDetailComponent,
        PayByCardAttrReqComponent
    ],
    entryComponents: [
        ReviseActComponent,
        PayComponent,
        PayWaitComponent,
        PayByCardAttrReqComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptorService,
            multi: true
        },
        AuthGuardService,
        AuthenticationService,
        ReportService,
        TenantService,
        AccountService,
        ObjectService,
        DogovorTypeService,
        CalcDocService,
        DogovorService,
        PayService,
        EsiaService
    ],
    bootstrap: [AppComponent]
})

export class AppModule {

}
