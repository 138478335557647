import { Component, OnInit, Input } from '@angular/core';
import { IAccount } from '../../models/IAccount';

@Component({
    selector: 'app-last-pay-info',
    templateUrl: 'last-pay-info.component.html',
    styleUrls: ['./last-pay-info.component.css']
})
/** Контроллер формы информации по последней оплате */
export class LastPayInfoComponent implements OnInit {

    /** Лицевой счет  */
    @Input()
    public payInfo: IAccount;

    /** Последняя ошибка */
    @Input()
    public error: string;

    /** Признак процесса загрузки данных */
    @Input()
    public isLoad: boolean;

    /**
     * Конструктор
     */
    constructor() {
    }

    /** 
     * Действия выполняемые при создании объекта
     */
    public ngOnInit() {
    }
}
