import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';
import { LoginComponent } from './components/login/login.component';
import {MainComponent} from './components/main/main.component';
import {ListDogovorsComponent} from './components/list-dogovors/list-dogovors.component';
import {DogovorInfoComponent} from './components/dogovor-info/dogovor-info.component';
import {ListDocumentsComponent} from './components/list-documents/list-documents.component';
import {PasswordRecoveryComponent} from './components/password-recovery/password-recovery.component';
import {RegistrationComponent} from './components/registration/registration.component';
import {TenantDetailComponent} from './components/tenant/tenant-detail.component';

const appRoutes: Routes = [
    { path: '', component: MainComponent, canActivate: [AuthGuardService] },
    { path: 'login', component: LoginComponent },
    { path: 'password-recovery', component: PasswordRecoveryComponent },
    { path: 'registration', component: RegistrationComponent },
    { path: 'main', component: MainComponent, canActivate: [AuthGuardService] },
    { path: 'list-dogovors', component: ListDogovorsComponent, canActivate: [AuthGuardService] },
    { path: 'dogovor-info', component: DogovorInfoComponent, canActivate: [AuthGuardService] },
    { path: 'list-documents', component: ListDocumentsComponent, canActivate: [AuthGuardService] },
    { path: 'tenant', component: TenantDetailComponent, canActivate: [AuthGuardService] },
    { path: '**', redirectTo: ''  }

];

export const routing = RouterModule.forRoot(appRoutes);
