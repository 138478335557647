import { Input, OnInit, Inject } from '@angular/core';
import { Component } from '@angular/core';
import * as FileSaver from 'file-saver';
import { IReport } from '../../models/IReport';
import { ReportService } from '../../services/report.service';
import { NgbDateParserFormatter, NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateRUParserFormatter } from '../../providers/ngb-date-parser-formatter';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { I18n, CustomDatepickerI18n } from '../../providers/CustomDatepickerI18n ';
import {UtilsService} from '../../services/utils.service';


@Component({
    selector: 'app-revise-act',
    templateUrl: 'revise-act.component.html',
    styleUrls: ['revise-act.component.css'],
    providers: [{ provide: NgbDateParserFormatter, useClass: NgbDateRUParserFormatter }, I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
/** Акт-сверки */
export class ReviseActComponent implements OnInit {
    
    public accountId: string;
    /** Дата начала */
    public beginDate: NgbDateStruct;
    /** Дата окончания */
    public endDate: NgbDateStruct;
    /** Список отчетов */
    public reports: IReport[];
    /** Текущий отчет */
    public currentReport: IReport;
    /** Положение мыши по X */
    public mouseX: any;
    /** Положение мыши по Y */
    public mouseY: any;
    /** Сообщение об ошибке */
   public errorMessage: string;
    /** Минимальная дата для составления акта сверки */
    public minDate: NgbDateStruct;

    /**
     * Конструктор
     * @param data Данные передаваемые контролу
     * @param reportService Сервис отчетов
     * @param dialModalRef Ссылка на диалог
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected reportService: ReportService,
        public dialModalRef: MatDialogRef<any>
    ) {
        this.accountId = this.data.account.Id;
        this.reportService.getReports().subscribe(
            (data2) => {
                this.reports = data2;
                this.currentReport = this.reports[0];
            }
        );
        this.reportService.getMinReviseAct().subscribe(
            (minReviseDate) => {
                let formatter = new NgbDateRUParserFormatter();
                this.minDate  = formatter.parse(minReviseDate.toString());
            }
        );
    }

    /** Инициализация */
    public ngOnInit(): void {
        if (window.innerWidth < 900){
            this.mouseY = this.data.mouseY - 280;
            this.dialModalRef.updatePosition({ top: this.mouseY + 'px'});
        } else {
            this.mouseX = this.data.mouseX - 225;
            this.mouseY = this.data.mouseY - 150;
            this.dialModalRef.updatePosition({ top: this.mouseY + 'px', left: this.mouseX +'px' });
        }
    }

    /**
     * Нажатие на кнопку "ОК"
     */
    public onCreateButtonClick() {
        const formatter = new NgbDateRUParserFormatter();
        this.errorMessage = null;
        this.reportService.getReviseActReport(this.currentReport.Id,
            formatter.format(this.beginDate), formatter.format(this.endDate),
            this.accountId).subscribe(
                (response) => {
                    FileSaver.saveAs(response, this.currentReport.Name + '.pdf');
                }, (error) => {
                    this.errorMessage = UtilsService.getErrorText(error);
                });
    }
}
