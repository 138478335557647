import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IDogovorType } from '../models/IDogovorType';

/** Сервис для работы с типами договора */
@Injectable()
export class DogovorTypeService {

    /**
     * Базовый URL для API
     */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Клиент для работы с http
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl + 'api/dogovorType';
    }

    /**
     * Получение типов договоров
     */
    public get(): Observable<IDogovorType[]> {
        return this.http.get<IDogovorType[]>(this.apiUrl);
    }
}
