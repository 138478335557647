import { Pipe, PipeTransform } from '@angular/core';

/**
 * Склонение по числам.
 */
@Pipe({
    name: 'declension'
})
export class DeclensionPipe implements PipeTransform {
    /**
     * Получение нужной формы существительного для данного числа
     * @param value Число
     * @param textForms Массив существительных, например ['договор', 'договора', 'договоров']
     * @returns {string} Нужное существительное для данного числа
     */
    public transform(value: number, textForms: string[]) {
        value = Math.abs(value) % 100;
        const n1 = value % 10;

        if (value > 10 && value < 20) {
            return textForms[2];
        }

        if (n1 > 1 && n1 < 5) {
            return textForms[1];
        }

        if (n1 == 1) {
            return textForms[0];
        }

        return textForms[2];
    }
}
