import {MAT_DIALOG_DATA} from '@angular/material';
import {Component, Inject, OnInit} from '@angular/core';
import {PayService} from '../../services/pay.service';
import { UtilsService } from '../../services/utils.service';
import { IDebt } from '../../models/IDebt';
import { TenantService } from '../../services/tenant.service';

@Component({
  selector: 'pay-by-card-attr-req',
  templateUrl: 'pay-by-card-attr-req.component.html',
  styleUrls: ['./pay-by-card-attr-req.component.css']
})
/** Контроллер блока ожидания проведения оплаты */
export class PayByCardAttrReqComponent implements OnInit {

  /** Последняя ошибка с сервера */
  public error: string;

  /** Почта */
  public mail: string;

  /** Телефон */
  public phone: string;
  
  /** Операции к оплате */
  public opersToPay: IDebt[] = [];

  /** Признак заугрузки данных */
  public loaded: boolean = false;

  /**
   * Конструктор
   * @param data Данные переданные в диалоговое окно
   * @param payService Сервис платежей
   */
  constructor(
      @Inject(MAT_DIALOG_DATA) public data: any,
      protected payService: PayService,
      protected tenantService: TenantService) {
  }

  /** Действия выполняемые при создании объекта,
   * инициализация полей формы и адреса перехода
   */
  public ngOnInit() {
    this.opersToPay = this.data.opersToPay;
    this.tenantService.getPayRequisites().subscribe(
      (info) => {        
          this.mail = info.find(x => x.FieldName == "EMAIL").Value;
          this.phone = info.find(x => x.FieldName == "PHONE").Value;
      },
      (error) => {
          console.log(error);
          this.error = UtilsService.getErrorText(error);
      },
      () => {
        this.loaded = true;
      });
  }

  /**
   * Оплатить
   */
  public payByCard() {
    this.payService.createPayByCard(this.opersToPay).subscribe(
        (url) => {
            if (url !== 'error') {
                window.location.href = url;
            }
        },
        (error) => {
            console.log(error);
            this.error = UtilsService.getErrorText(error);
        });
}
}
