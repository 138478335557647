import { OnInit, Input, SimpleChanges, OnChanges, Renderer2, ElementRef, HostListener } from "@angular/core";
import { Component } from "@angular/core";
import { IAccount } from "../../models/IAccount";
import { ObjectService } from "../../services/object.service";
import { IObject } from "../../models/IObject";
import { fromEvent } from 'rxjs/observable/fromEvent';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/takeUntil';
import { Subject, Observable, interval } from "rxjs";
import { throttle } from "rxjs/operators";
import { MatDialog } from "@angular/material";
import { ReviseActComponent } from "../revise-act/revise-act.component";
import { PayComponent } from "../pay/pay.component";
import {UtilsService} from "../../services/utils.service";

@Component({
    selector: 'app-dogovor-short',
    templateUrl: 'dogovor-short.component.html',
    styleUrls: ['./dogovor-short.component.css']
})
/** Контроллер краткой информации по договору */
export class DogovorShortComponent implements OnInit, OnChanges {

    /** Лицевой счет */
    @Input()
    public account: IAccount;

    /** true - используется в списке договоров, false - используется на главной странице */
    @Input()
    public useInListDogovors = false;

    /** Объекты */
    public objectList: IObject[] = [];
    /** Ширина столбца наименования */
    public widthName: number;
    /** Ширина столбца адреса */
    public widthAddress: number;
    /** Ширина столбца площади */
    public widthPl: number;

    /** Предыдущее положение мышки по горизонатле */
    private oldMouseX: any;
    /** Событие движения мышки */
    private mousemove$: any;
    /** Событие отжатия кнопки мыши */
    private mouseup$;

    /** События нажатия на ползунок столбца */
    private mousedownNameLeft$: any;
    private mousedownNameRight$: any;
    private mousedownPlRight$: any;
    private mousedownPlLeft$: any;
    /** Подписка на слежение удерживания ползунка */
    private _subHoldRightNameGrabber: any;
    private _subHoldLeftNameGrabber: any;
    private _subHoldRightPlGrabber: any;
    private _subHoldLeftPlGrabber: any;

    /**
     * Режим экрана
     * 1 - 1920х1080
     * 2 - 1024х768
     * 3 - 640х360
     */
    private screenMode: number = 0;

    /** для throttle событий изменения экрана */
    private windowChanged: Subject<void> = new Subject<void>();
    private windowChangeThrottled: Observable<void>;

    /** Последняя ошибка запроса к серверу */
    @Input()
    public error: string;

    /**
    * Конструктор
    * @param objectService Сервис работы с объектами
    */
    constructor(
        public renderer: Renderer2,
        private objectService: ObjectService,
        private _el: ElementRef,
        public dialog: MatDialog) {
    }

    /** При изменении входных данных */
    ngOnChanges(changes: SimpleChanges) {
        if (changes.account && changes.account.currentValue) {
            this.objectService.get(changes.account.currentValue.DogovorId).subscribe(
                (objects) => {
                    this.objectList = objects;
                    this.error = null;
                }, (error) => {
                    console.log(error);
                    this.error = UtilsService.getErrorText(error);
                });

            if (!this._subHoldRightNameGrabber) {
                this.initGrabbers()
            }
        }
    }


    /** При создании объекта */
    ngOnInit(): void {
        this.windowChangeThrottled = this.windowChanged.pipe(throttle(val => interval(500)));
        this.windowChangeThrottled.subscribe(() => {
            this.handleResize();
        });
        this.handleResize();
    }

    /** Событие изменения размера экрана */
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.windowChanged.next();
    }

    /**
     * Открытие окна акта сверки
     * @param elementAttr  Атрибут элемента для которого открываем справочник
     */
    public openReviseActDialog(event) {
        const dialogRef = this.dialog.open<ReviseActComponent>(ReviseActComponent, {
            panelClass: 'app-dialog-container',
            data: {
                account: this.account,
                mouseX: event.x,
                mouseY: event.y
            }
        });
    }

    /** Открыть компонент оплаты */
    public openPayDialog() {
        const dialogRef = this.dialog.open<PayComponent>(PayComponent, {
            panelClass: 'app-dialog-container', 
            data: {
                account: this.account,
                sumToPay: this.account.Debt
            }
        });
    }

    /** Получить стиль рамки для информирования о задолженностях */
    public getContentBorderStyle(): string {
        if (this.useInListDogovors) {
            return '';
        } else if (this.account && this.account.Debt > 0) {
            return 'app-content-red';
        } else {
            return  'app-content-blue';
        }
    }

    /**
     * Получить высоту блока в зависимости от места применения этого блока и режима экрана
     * @returns {any}
     */
    public getContentHeight(): string {
        if ( ! this.useInListDogovors && (this.screenMode === 1 || this.screenMode == 2)) {
            return '446px';
        }
        return 'auto';
    }

    /** Инициализация ползунков растягивания колонок */
    private initGrabbers() {
        let grabberNameRight = this.renderer.selectRootElement('#grabber-name-right');
        this.mousedownNameRight$ = fromEvent(grabberNameRight, 'mousedown');
        this.mousedownNameRight$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        let grabberNameLeft = this.renderer.selectRootElement('#grabber-name-left');
        this.mousedownNameLeft$ = fromEvent(grabberNameLeft, 'mousedown');
        this.mousedownNameLeft$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        let grabberPlRight = this.renderer.selectRootElement('#grabber-pl-right');
        this.mousedownPlRight$ = fromEvent(grabberPlRight, 'mousedown');
        this.mousedownPlRight$.subscribe((e) => {
            this.oldMouseX = e.x;
        });


        let grabberPlLeft = this.renderer.selectRootElement('#grabber-pl-left');
        this.mousedownPlLeft$ = fromEvent(grabberPlLeft, 'mousedown');
        this.mousedownPlLeft$.subscribe((e) => {
            this.oldMouseX = e.x;
        });


        this.mousemove$ = fromEvent(this._el.nativeElement, 'mousemove');
        this.mouseup$ = fromEvent(this._el.nativeElement, 'mouseup');
        this.mouseup$.subscribe(() => {
            this.unsubGrabbers();
            this.registerGrabbers();
        });

        this.registerGrabbers();
    }

    /** Отписка от прослушки движения мышки при расширении столбцов */
    private unsubGrabbers() {
        if (this._subHoldRightNameGrabber) {
            this._subHoldRightNameGrabber.unsubscribe();
        }
        if (this._subHoldLeftNameGrabber) {
            this._subHoldLeftNameGrabber.unsubscribe();
        }
        if (this._subHoldRightPlGrabber) {
            this._subHoldRightPlGrabber.unsubscribe();
        }
        if (this._subHoldLeftPlGrabber) {
            this._subHoldLeftPlGrabber.unsubscribe();
        }
    }

    /** Подписываемся на ползунки расширения колонок */
    private registerGrabbers() {
        // switchMap is extremely helpful
        // map source observable to inner observable. remember it as switch to new observable.
        let mouseholdRight$ = this.mousedownNameRight$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdLeft$ = this.mousedownNameLeft$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdPlRight$ = this.mousedownPlRight$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdPlLeft$ = this.mousedownPlLeft$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);

        this._subHoldRightNameGrabber = mouseholdRight$.subscribe((e) => {
            this.onMoveNameGrabber(e);
        })
        this._subHoldLeftNameGrabber = mouseholdLeft$.subscribe((e) => {
            this.onMoveNameGrabber(e);
        })

        this._subHoldRightPlGrabber = mouseholdPlRight$.subscribe((e) => {
            this.onMovePlGrabber(e);
        })
        this._subHoldLeftPlGrabber = mouseholdPlLeft$.subscribe((e) => {
            this.onMovePlGrabber(e);
        })
    }

    /** РАсчет ширины столбцов при изменении размера столбца наименования */
    private onMoveNameGrabber(e) {
        let diff = (e.x - this.oldMouseX);
        this.oldMouseX = e.x;
        if (!Number.isNaN(diff)) {
            if (this.widthName + diff < 33) {
                this.widthName = 33;
                return;
            }
            this.widthName = this.widthName + diff;
            if (this.widthAddress - diff >= 33) {
                this.widthAddress = this.widthAddress - diff;
            } else if (this.widthPl - diff >= 33) {
                this.widthPl = this.widthPl - diff;
            } else {
                this.widthName = this.widthName - diff;
            }
        }
    }

    /** РАсчет ширины столбцов при изменении размера столбца площади */
    private onMovePlGrabber(e) {
        let diff = (e.x - this.oldMouseX);
        this.oldMouseX = e.x;
        if (!Number.isNaN(diff)) {
            if (this.widthPl - diff < 33) {
                this.widthPl = 33;
                return;
            }
            this.widthPl = this.widthPl - diff;
            if (this.widthAddress + diff >= 33) {
                this.widthAddress = this.widthAddress + diff;
            } else if (this.widthName + diff >= 33) {
                this.widthName = this.widthName + diff;
            } else {
                this.widthPl = this.widthPl + diff;
            }
        }
    }

    /** Обработать изменение экрана */
    private handleResize(): void {
        if (this.useInListDogovors) {
            if (window.innerWidth < 900 ) {
                if (this.screenMode !== 3) {
                    this.screenMode = 3;
                }
                this.widthName = (window.innerWidth - 120) / 2.61;
                this.widthAddress = (window.innerWidth - 120) / 2.9;
                this.widthPl = (window.innerWidth - 120) / 3.67;
            } else if (window.innerWidth > 900 && window.innerWidth < 1600 ) {
                if (this.screenMode !== 2) {
                    this.screenMode = 2;
                }
                this.widthName = (window.innerWidth - 170) / 2.61;
                this.widthAddress = (window.innerWidth - 170) / 2.9;
                this.widthPl = (window.innerWidth - 170) / 3.67;
            } else if (window.innerWidth > 1600 ) {
                if (this.screenMode !== 1) {
                    this.screenMode = 1;
                }
                this.widthName = 190;
                this.widthAddress = 170;
                this.widthPl = 132;
            }
        } else {
            if (window.innerWidth < 900 && this.screenMode !== 3) {
                this.screenMode = 3;
                this.widthName = 90;
                this.widthAddress = 100;
                this.widthPl = 60;
            } else if (window.innerWidth > 900 && window.innerWidth <= 1224 && this.screenMode !== 2) {
                this.screenMode = 2;
                this.widthName = 300;
                this.widthAddress = 380;
                this.widthPl = 157;
            } else if (window.innerWidth > 1224 && this.screenMode !== 1) {
                this.screenMode = 1;
                this.widthName = 190;
                this.widthAddress = 175;
                this.widthPl = 132;
            }
        }
    }
}