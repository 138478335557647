import { Component } from '@angular/core';

/**
 * Пустой компонент-заглушка
 */
@Component({
    selector: 'app-dummy-component',
    template: `<h2>Не реализовано</h2>`
})
export class DummyComponent {
}
