import {OnInit, ViewChild, Renderer2, ElementRef} from "@angular/core";
import {Component} from "@angular/core";
import {HostListener} from "@angular/core";
import {interval, Subject, Observable, fromEvent} from 'rxjs';
import { throttle } from 'rxjs/operators';
import {AgGridCustomTooltip} from "../ag-grid-custom-tooltip.component";
import {GridOptions, GridReadyEvent, ICellRendererParams} from "ag-grid-community/main";

import { AccountService } from "../../services/account.service";
import { AccountInfoAttrEnum } from "../../models/AccountInfoAttrEnum";
import { IAccount } from "../../models/IAccount";
import { DogovorTypeService } from "../../services/dogovor-type.service";
import { IDogovorType } from "../../models/IDogovorType";
import { ITotalSum } from "../../models/ITotalSum";
import {CurrencyPipe} from "@angular/common";
import {UtilsService} from "../../services/utils.service";


@Component({
    selector: 'app-list-dogovors',
    templateUrl: 'list-dogovors.component.html',
    styleUrls: ['./list-dogovors.component.css']
})
/** Контроллер списка договоров */
export class ListDogovorsComponent implements OnInit {
    /** Данные отображения в таблице */
    public rowData: IAccount[];
    /** Типы договоров */
    public dogovorTypes: IDogovorType[];
    /** Признак отображения детальной информации */
    public showDetailMode: boolean = false;
    /** Количество лицевых счетов */
    public accountCount: number;

    /** Количество значений на странице */
    public countRowOnPage: number = 25;
    /** Текущая страница */
    public currentPage: number = 1;
    /** Количество страниц */
    public countPage: number = 1;

    /** Выбронная строка */
    public selectedRow: IAccount;

    /** Итоговые суммы */
    public totalSum: ITotalSum;
    /** Признак отображения итоговых сумм */
    public isShowTotalSum: boolean = false;

    /**
     * Режим экрана
     * 1 - 1920х1080
     * 2 - 1024х768
     * 3 - 640х360
     */
    public screenMode: number;

    /** Последняя ошибка запроса к серверу */
    public error: string;

    /** Выбранный идентификатор типа договора */
    public dogType: IDogovorType;

   // @ViewChild(DogovorComponent, {static: false})
   // private dogovorComponent: DogovorComponent;

    /** для throttle событий изменения экрана */
    private windowChanged: Subject<void> = new Subject<void>();
    private windowChangeThrottled: Observable<void>;
    widthDocNo: number;
    widthAcc: number;
    widthAyear: number;
    widthDebt: number;
    widthNextPay: number;

    /** Предыдущее положение мышки по горизонатле */
    private oldMouseX: any;
    /** Событие движения мышки */
    private mousemove$: any;
    /** Событие отжатия кнопки мыши */
    private mouseup$;
    
    /** События нажатия на ползунок столбца */
    private mousedownAccRight$: any;
    private mousedownAccLeft$: any;
    private mousedownAyearRight$: any;
    private mousedownAyearLeft$: any;
    private mousedownDebtLeft$: any;
    private mousedownDebtRight$: any;
    private mousedownNextPayLeft$: any;
    private mousedownNextPayRight$: any;
    /** Подписка на слежение удерживания ползунка */
    private _subHoldRightAccGrabber: any;
    private _subHoldLeftAccGrabber: any;
    private _subHoldRightDebtGrabber: any;
    private _subHoldLeftDebtGrabber: any;
    private _subHoldRightAyearGrabber: any;
    private _subHoldLeftAyearGrabber: any;
    private _subHoldRightNextPayGrabber: any;
    private _subHoldLeftNextPayGrabber: any;
    public AYearSum: number;
    public DebtSum: number;

    /**
     * Конструктор
     * @param accountService Сервис лицевых счетов
     * @param dogovorTypeService Сервис типов договоров
     */
    constructor(
        public renderer: Renderer2,
        private _el: ElementRef,
        private accountService: AccountService,
        private dogovorTypeService: DogovorTypeService
    ) {
    }

    /** Инициализация */
    ngOnInit(): void {
        this.windowChangeThrottled = this.windowChanged.pipe(throttle(val => interval(500)));
        this.windowChangeThrottled.subscribe(() => {
            this.handleResize();
        });
        this.handleResize();

            
        if (!this._subHoldRightAccGrabber) {
            this.initGrabbers()
        }

        // Получаем типы договоров
        this.dogovorTypeService.get().subscribe(
            (types) => {
                this.dogovorTypes = types;
                this.dogType = this.dogovorTypes[0];
                this.loadAccounts();
                this.setError(null);
            },
            (error) => {
                console.log(error);
                this.setError(UtilsService.getErrorText(error));
            });

    }

    /** Событие изменения типа договора */
    public onChangeDogType(dog: IDogovorType) {
        this.dogType = dog;
        this.currentPage = 1;
        this.loadAccounts();
        if (this.isShowTotalSum) {
            this.getTotalSum();
        }
    }

    /** При изменении количества отображаемых страниц */
    public onChangeCountOnPage(count: number) {
        if (this.countRowOnPage === count) {
            return;
        }
        this.countRowOnPage = count;
        this.loadAccounts();
    }

    /** Событие выбора ячейки */
    public onRowClicked(rowData: any) {
        this.selectedRow = rowData;
    }

    public onRowDbClicked(event: any) {
        window.open("/dogovor-info?dogId=" + event.data.DogovorId);
    }

    /** При нажатии на подсчет итоговых сумм */
    public onClickSum() {
        this.isShowTotalSum = !this.isShowTotalSum;
        if (this.isShowTotalSum) {
            this.getTotalSum();
        }
    }

    /** Клик по показать инфо в режиме мобильного экрана */
    public clickShowDetail() {
        this.showDetailMode = true;
        //this.dogovorComponent.onResize();
    }

    /** Клик по обратно в список в режиме мобильного экрана */
    public clickHideDetail() {
        this.showDetailMode = false;
    }

    /** Переход на первую страницу */
    public toFirstPage() {
        if (this.currentPage == 1)
            return;
        this.currentPage = 1;
        this.loadAccounts();
    }

    /** Переход на предыдущую страницу */
    public toPrevPage() {
        if (this.currentPage - 1 < 1 || this.currentPage == this.currentPage - 1)
            return;
        this.currentPage = this.currentPage - 1;
        this.loadAccounts();
    }

    /** Переход на следующую страницу */
    public toNextPage() {
        if (this.currentPage + 1 > this.countPage || this.currentPage == this.currentPage + 1)
            return;
        this.currentPage = this.currentPage + 1;
        this.loadAccounts();
    }

    /** Переход на последнюю страницу */
    public toLastPage() {
        if (this.currentPage == this.countPage)
            return;
        this.currentPage = this.countPage;
        this.loadAccounts();
    }

    /**
     * Вызывается при полной загрузке грида
     * @param params
     */
    public onGridReady(params: GridReadyEvent) {
        this.handleResize();

        // NB! This is unsupported and may break at any time
        try {
            //console.log((params.api as any).context)
            (params.api as any).context.beans.tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 0;
        } catch (e) {
            console.error(e);
        }
    }

    /** Событие изменения размера экрана */
    @HostListener('window:resize', ['$event'])
    public onResize() {
        this.windowChanged.next();

    }

    /**
     * Загрузка лицевых счетов
     */
    private loadAccounts() {
        this.accountService.getCount(this.dogType.Id).subscribe(
            (count) => {
                this.accountCount = count;
                //this.currentPage = 1;
                this.countPage = Math.floor(this.accountCount / this.countRowOnPage) + 1;

                this.accountService.getByDogType(AccountInfoAttrEnum.NextPay | AccountInfoAttrEnum.Debt, this.dogType.Id, this.currentPage, this.countRowOnPage).subscribe(
                    (accs) => {
                        this.rowData = accs;
                        this.setError(null);
                    },
                    (error) => {
                        console.log(error);
                        this.setError(UtilsService.getErrorText(error));
                    });
            },
            (error) => {
                console.log(error);
                this.setError(UtilsService.getErrorText(error));
            });
    }

    /** Получение итоговых сумм */
    private getTotalSum() {
        this.accountService.getTotalSum(this.dogType.Id).subscribe(
            (sum) => {
                this.totalSum = sum;
                this.AYearSum = sum.AyearSum;
                this.DebtSum = sum.DebtSum;
            },
            (error) => {
                console.log(error);
            });

    }
    
    /**
     * Переход к договору
     * @param selectedAccount Выбарнный лицевой счет
     */
    public toDogovorClick(selectedAccount: IAccount) {
        if (selectedAccount != this.selectedRow)
            return;

        window.open("/dogovor-info?dogId=" + selectedAccount.DogovorId);

    }


    /** Обработать изменение экрана */
     private handleResize() {
         
        if (window.innerWidth < 900 && this.screenMode !== 3) {
            this.screenMode = 3;
            this.isShowTotalSum = false;
            this.widthDocNo = 147;
            this.widthAcc = 126;
            this.widthAyear = 0;
            this.widthDebt = 0;
            this.widthNextPay = 0;
        } else if (window.innerWidth > 900 && window.innerWidth <= 1224 && this.screenMode !== 2) {
            this.screenMode = 2;
            this.widthDocNo = 170;
            this.widthAcc = 178;
            this.widthAyear = 158;
            this.widthDebt = 195;
            this.widthNextPay = 229;
        } else if (window.innerWidth > 1224 && this.screenMode !== 1) {
            this.screenMode = 1;
            this.widthDocNo = 150;
            this.widthAcc = 178;
            this.widthAyear = 158;
            this.widthDebt = 135;
            this.widthNextPay = 150;
        }
     }

     
    /** Инициализация ползунков растягивания колонок */
    private initGrabbers() {
        let grabberAccRight = this.renderer.selectRootElement('#grabber-acc-right');
        this.mousedownAccRight$ = fromEvent(grabberAccRight, 'mousedown');
        this.mousedownAccRight$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        let grabberAccLeft = this.renderer.selectRootElement('#grabber-acc-left');
        this.mousedownAccLeft$ = fromEvent(grabberAccLeft, 'mousedown');
        this.mousedownAccLeft$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        let grabberAyearRight = this.renderer.selectRootElement('#grabber-ayear-right');
        this.mousedownAyearRight$ = fromEvent(grabberAyearRight, 'mousedown');
        this.mousedownAyearRight$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        let grabberAyearLeft = this.renderer.selectRootElement('#grabber-ayear-left');
        this.mousedownAyearLeft$ = fromEvent(grabberAyearLeft, 'mousedown');
        this.mousedownAyearLeft$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        let grabberDebtRight = this.renderer.selectRootElement('#grabber-debt-right');
        this.mousedownDebtRight$ = fromEvent(grabberDebtRight, 'mousedown');
        this.mousedownDebtRight$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        let grabberDebtLeft = this.renderer.selectRootElement('#grabber-debt-left');
        this.mousedownDebtLeft$ = fromEvent(grabberDebtLeft, 'mousedown');
        this.mousedownDebtLeft$.subscribe((e) => {
            this.oldMouseX = e.x;
        });
        
        let grabberNextPayRight = this.renderer.selectRootElement('#grabber-nextPay-right');
        this.mousedownNextPayRight$ = fromEvent(grabberNextPayRight, 'mousedown');
        this.mousedownNextPayRight$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        let grabberNextPayLeft = this.renderer.selectRootElement('#grabber-nextPay-left');
        this.mousedownNextPayLeft$ = fromEvent(grabberNextPayLeft, 'mousedown');
        this.mousedownNextPayLeft$.subscribe((e) => {
            this.oldMouseX = e.x;
        });

        this.mousemove$ = fromEvent(this._el.nativeElement, 'mousemove');
        this.mouseup$ = fromEvent(this._el.nativeElement, 'mouseup');
        this.mouseup$.subscribe(() => {
            this.unsubGrabbers();
            this.registerGrabbers();
        });

        this.registerGrabbers();
    }

    /** Отписка от прослушки движения мышки при расширении столбцов */
    private unsubGrabbers() {
        if (this._subHoldRightAccGrabber) {
            this._subHoldRightAccGrabber.unsubscribe();
        }
        if (this._subHoldLeftAccGrabber) {
            this._subHoldLeftAccGrabber.unsubscribe();
        }
        if (this._subHoldRightAyearGrabber) {
            this._subHoldRightAyearGrabber.unsubscribe();
        }
        if (this._subHoldLeftAyearGrabber) {
            this._subHoldLeftAyearGrabber.unsubscribe();
        }
        if (this._subHoldRightDebtGrabber) {
            this._subHoldRightDebtGrabber.unsubscribe();
        }
        if (this._subHoldLeftDebtGrabber) {
            this._subHoldLeftDebtGrabber.unsubscribe();
        }
        if (this._subHoldRightNextPayGrabber) {
            this._subHoldRightNextPayGrabber.unsubscribe();
        }
        if (this._subHoldLeftNextPayGrabber) {
            this._subHoldLeftNextPayGrabber.unsubscribe();
        }
    }

    /** Подписываемся на ползунки расширения колонок */
    private registerGrabbers() {
        // switchMap is extremely helpful
        // map source observable to inner observable. remember it as switch to new observable.
        let mouseholdAccRight$ = this.mousedownAccRight$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdAccLeft$ = this.mousedownAccLeft$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdAyearRight$ = this.mousedownAyearRight$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdAyearLeft$ = this.mousedownAyearLeft$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdDebtRight$ = this.mousedownDebtRight$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdDebtLeft$ = this.mousedownDebtLeft$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdNextPayRight$ = this.mousedownNextPayRight$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);
        let mouseholdNextPayLeft$ = this.mousedownNextPayLeft$.switchMap(() => this.mousemove$).takeUntil(this.mouseup$);


        this._subHoldRightAccGrabber = mouseholdAccRight$.subscribe((e) => {
            this.onMoveAccGrabber(e);
        })
        this._subHoldLeftAccGrabber = mouseholdAccLeft$.subscribe((e) => {
            this.onMoveAccGrabber(e);
        })

        this._subHoldRightAyearGrabber = mouseholdAyearRight$.subscribe((e) => {
            this.onMoveAyearGrabber(e);
        })
        this._subHoldLeftAyearGrabber = mouseholdAyearLeft$.subscribe((e) => {
            this.onMoveAyearGrabber(e);
        })

        this._subHoldRightDebtGrabber = mouseholdDebtRight$.subscribe((e) => {
            this.onMoveDebtGrabber(e);
        })
        this._subHoldLeftDebtGrabber = mouseholdDebtLeft$.subscribe((e) => {
            this.onMoveDebtGrabber(e);
        })       
        
        this._subHoldRightNextPayGrabber = mouseholdNextPayRight$.subscribe((e) => {
            this.onMoveNextPayGrabber(e);
        })
        this._subHoldLeftNextPayGrabber = mouseholdNextPayLeft$.subscribe((e) => {
            this.onMoveNextPayGrabber(e);
        })
    }

    /** РАсчет ширины столбцов при изменении размера столбца номера договора */
    private onMoveAccGrabber(e) {
        let diff = (e.x - this.oldMouseX);
        this.oldMouseX = e.x;
        if (!Number.isNaN(diff)) {
            if (this.widthDocNo + diff >= 33) {
                this.widthDocNo = this.widthDocNo + diff;
            } else {
                return;
            }

            if (this.widthAcc - diff >= 33) {
                this.widthAcc = this.widthAcc - diff;
            } else if (this.widthAyear - diff >= 33) {
                this.widthAyear = this.widthAyear - diff;
            } else if (this.widthDebt - diff >= 33) {
                this.widthDebt = this.widthDebt - diff;
            }  else if (this.widthNextPay - diff >= 33) {
                this.widthNextPay = this.widthNextPay - diff;
            } else {
                this.widthDocNo = this.widthDocNo - diff;
            }
        }
    }

    /** РАсчет ширины столбцов при изменении размера столбца годовой суммы */
    private onMoveAyearGrabber(e) {
        let diff = (e.x - this.oldMouseX);
        this.oldMouseX = e.x;
        if (!Number.isNaN(diff)) {

            if (this.widthAcc + diff >= 33) {
                this.widthAcc = this.widthAcc + diff;
            } else if (this.widthDocNo + diff >= 33) {
                this.widthDocNo = this.widthDocNo + diff;
            } else {
                return;
            }

            if (this.widthAyear - diff >= 33) {
                this.widthAyear = this.widthAyear - diff;
            } else if (this.widthDebt - diff >= 33) {
                this.widthDebt = this.widthDebt - diff;
            }  else if (this.widthNextPay - diff >= 33) {
                this.widthNextPay = this.widthNextPay - diff;
            } else {
                this.widthAcc = this.widthAcc - diff;
            }
        }
    }

    
    /** Расчет ширины столбцов при изменении размера столбца задолженности */
    private onMoveDebtGrabber(e) {
        let diff = (e.x - this.oldMouseX);
        this.oldMouseX = e.x;
        if (!Number.isNaN(diff)) {
            
            if (this.widthAyear + diff >= 33) {
                this.widthAyear = this.widthAyear + diff;
            } else if (this.widthAcc + diff >= 33) {
                this.widthAcc = this.widthAcc + diff;
            } else if (this.widthDocNo + diff >= 33) {
                this.widthDocNo = this.widthDocNo + diff;
            } else {
                return;
            }

            if (this.widthDebt - diff >= 33) {
                this.widthDebt = this.widthDebt - diff;
            }  else if (this.widthNextPay - diff >= 33) {
                this.widthNextPay = this.widthNextPay - diff;
            } else {
                this.widthAyear = this.widthAyear - diff;
            }
        }
    }

    
    /** РАсчет ширины столбцов при изменении размера столбца суммы следующего платежа */
    private onMoveNextPayGrabber(e) {
        let diff = (e.x - this.oldMouseX);
        this.oldMouseX = e.x;
        if (!Number.isNaN(diff)) {
            
            if (this.widthDebt + diff >= 33) {
                this.widthDebt = this.widthDebt + diff;
            } else if (this.widthAyear + diff >= 33) {
                this.widthAyear = this.widthAyear + diff;
            } else if (this.widthAcc + diff >= 33) {
                this.widthAcc = this.widthAcc + diff;
            } else if (this.widthDocNo + diff >= 33) {
                this.widthDocNo = this.widthDocNo + diff;
            } else {
                return;
            }

            if (this.widthNextPay - diff >= 33) {
                this.widthNextPay = this.widthNextPay - diff;
            } else {
                this.widthDebt = this.widthDebt - diff;
            }
        }
    }

    private setError(error: string) {
        this.error = error;
    }
}