import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'mobile-version-info',
    templateUrl: 'mobile-version.component.html',
    styleUrls: ['./mobile-version.component.css']
})
/** Контроллер блока информации о мобильной версии */
export class MobileVersionComponent implements OnInit {

    /** Сообщение об ошибке */
    public errorMessage: string;

    /**
     * Конструктор
     * @param formBuilder Построитель элементов формы
     * @param route Объект хранящий информацию о запросе
     * @param router Модуль маршрутизации
     * @param authenticationService Сервис аутентификации пользователя
     */
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router) {
    }

    /** Действия выполняемые при создании объекта,
     * инициализация полей формы и адреса перехода
     */
    public ngOnInit() {
    }
}
