import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ICalcDoc } from '../models/ICalcDoc';

/** Сервис для работы с документами основаниями */
@Injectable()
export class CalcDocService {

    /**
     * Базовый URL для API
     */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Клиент для работы с http
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl + 'api/calcdoc';
    }

    /**
     * Получение документов оснований по договору
     * @param dogovorId Идентификатор договора
     * @param fullInfo true если нужна подробная информация по контрагентам и объектам
     */
    public get(dogovorId: string, fullInfo: boolean): Observable<ICalcDoc[]> {
        return this.http.get<ICalcDoc[]>(this.apiUrl + (fullInfo ? '/getextinfo' : '/getbaseinfo'), {
            params: { dogId: dogovorId }
        });
    }
}
