import { Input, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { IAccount } from '../../models/IAccount';
import {PayComponent} from '../pay/pay.component';
import {ReviseActComponent} from '../revise-act/revise-act.component';

@Component({
    selector: 'app-account',
    templateUrl: 'account.component.html',
    styleUrls: ['./account.component.css']
})
/** Контроллер лицевого счета */
export class AccountComponent implements OnInit {

    /** Лицевой счет */
    @Input()
    public account: IAccount;

    @Input()
    public error: string;

    constructor( private dialog: MatDialog) {
    }

    /** Инициализация */
    // tslint:disable-next-line:no-empty
    public ngOnInit(): void {}

    /** Открыть компонент оплаты */
    public openPayDialog() {
        const dialogRef = this.dialog.open<PayComponent>(PayComponent, {
            panelClass: 'app-dialog-container',
            data: {
                account: this.account,
                sumToPay: this.account.Debt
            }
        });
    }

    /**
     * Открытие окна акта сверки
     * @param elementAttr  Атрибут элемента для которого открываем справочник
     */
    public openReviseActDialog(event) {
        const dialogRef = this.dialog.open<ReviseActComponent>(ReviseActComponent, {
            panelClass: 'app-dialog-container',
            data: {
                account: this.account,
                mouseX: event.x,
                mouseY: event.y
            }
        });
    }

}
