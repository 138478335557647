import { Component, OnInit } from '@angular/core';
import { IAccount } from '../../models/IAccount';
import { AccountService } from '../../services/account.service';
import { AccountInfoAttrEnum } from '../../models/AccountInfoAttrEnum';
import {UtilsService} from "../../services/utils.service";


@Component({
    selector: 'app-last-pay-list',
    templateUrl: 'last-pay-list.component.html',
    styleUrls: ['./last-pay-list.component.css']
})
/** Контроллер формы списка последних оплат */
export class LastPayListComponent implements OnInit {

    /** Последнии опалты */
    public paysInfo: IAccount []  = [];

    /** Признак отображения подробной информации */
    public isShowPays: boolean = true;

    /** Признак отображения подробной информации */
    public isShowInformationPays: boolean = false;

    /** Последняя ошибка запроса к серверу */
    public error: string;

    /** Признак процесса загрузки данных */
    public isLoad: boolean = true;

    /**
     * Конструктор
     * @param accountService Сервис лицевых счетов
     */
    constructor(
        private accountService: AccountService) {
    }

    /** 
     * Действия выполняемые при создании объекта
     */
    public ngOnInit() {
        this.accountService.get(AccountInfoAttrEnum.LastPay).subscribe(
            (accs) => {
                this.paysInfo = accs.sort((a, b) => new Date(b.LastPayDate).getTime() - new Date(a.LastPayDate).getTime());
                this.error = null;
                this.isLoad = false;
            },
            (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
                this.isLoad = false;
            });
    }

    /** При скрытии/раскрытии подробной информации о последних оплатах */
    public showHideClick() {
        this.isShowPays = !this.isShowPays;
    }

    /** При скрытии/раскрытии подсказки о недавних платежах */
    public showHideInformationClick(): void {
        this.isShowInformationPays = !this.isShowInformationPays;
    }
}
