import { Component, OnInit } from '@angular/core';
import { ITenantField } from '../../models/ITenantField';
import { TenantService } from '../../services/tenant.service';

@Component({
    selector: 'app-tenant-detail',
    templateUrl: 'tenant-detail.component.html',
    styleUrls: ['./tenant-detail.component.css']
})
/** Регистрация пользователя */
export class TenantDetailComponent implements OnInit {

    /** Список полей для заполнения (без нового логина и пароля) */
    public fields: ITenantField[] = [];

    /** Флаг выполнения запроса входа */
    public loading = false;
    /** Флаг что поля ввода не валидные */
    public fieldsNotValid: boolean = true;
    /** Флаг подтверждения данных формы */
    public submitted = false;
    /** Сообщение об ошибке */
    public errorMessage: string;

    /**
     * Конструктор
     * @param formBuilder Построитель элементов формы
     * @param router Модуль маршрутизации
     * @param tenantService Сервис арендатора
     */
    constructor(
        private tenantService: TenantService) {
    }

    public ngOnInit() {
        // запрос полей для заполнения
        this.tenantService.getDetail().subscribe(
            (data) => {
                data.forEach((field) => {
                    if (field.DataType === 'Date' && field.Value.length > 0) {
                        console.log(field);
                        field.Value = this.parseDate(field.Value).toLocaleDateString();
                    }
                });
                this.fields = data;
            }, (error) => {
                this.loading = false;
                this.errorMessage = error.error.ExceptionMessage ? error.error.ExceptionMessage : error.message;
            }
        );
    }
    /** Сменить тип поля ввода пароля */
    public changePasswordType(input: any) {
        input.type = input.type === 'password' ? 'text' : 'password';
    }

    /**
     * Из utc в объект Date
     * @param dateUtc дата в формате utc
     * @returns {Date} объект даты
     */
    public parseDate(dateUtc: string): Date {
        if (dateUtc == null) {
            return null;
        }
        return new Date(dateUtc);
    }
}
