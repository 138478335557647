import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ITenant } from '../models/ITenant';
import { ITenantField } from '../models/ITenantField';

/** Сервис для работы с арендатором */
@Injectable()
export class TenantService {

    /**
     * Базовый URL для API
     */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Клиент для работы с http
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl + 'api/tenant';
    }

    /**
     * Получение арендатора
     */
    public get(): Observable<ITenant> {
        return this.http.get<ITenant>(this.apiUrl);
    }

    /**
     * Получение детальной информации арендатора
     */
    public getDetail(): Observable<ITenantField[]> {
        return this.http.get<ITenantField[]>(this.apiUrl + '/detail');
    }

    /**
     * Получение реквизитов для опалты
     */
    public getPayRequisites() {
        return this.http.get<ITenantField[]>(this.apiUrl + '/payrequisites');
    }
}
