import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './services/authentication.service';

/**
 * Корневой компонент
 */
@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.css']
})
export class AppComponent implements OnInit {

    /** Залогинен ли пользователь */
    public isLoggedIn$: Observable<boolean>;

    /**
     * Конструктор
     * @param router для ручной навигации
     * @param authenticationService сервис авторизации
     * @param dialog Сервис для запуска модальных окон
     */
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public dialog: MatDialog) {
    }

    /**
     * Инициализация компонента
     */
    public ngOnInit(): void {
        this.isLoggedIn$ = this.authenticationService.isLoggedIn();
    }

    /**
     * Нажатие кнопки "выход"
     */
    public onLogout() {
        this.authenticationService.logout();
        this.router.navigateByUrl('/login');
    }
}
