import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITenantField } from '../../models/ITenantField';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
    selector: 'app-registration',
    templateUrl: 'registration.component.html',
    styleUrls: ['./registration.component.css']
})
/** Регистрация пользователя */
export class RegistrationComponent implements OnInit {

    /** Список полей для заполнения (без нового логина и пароля) */
    public fields: ITenantField[] = [];

    /** Флаг выполнения запроса входа */
    public loading = false;
     /** Флаг выполнения запроса входа */
    public isFieldsNotLoaded = true;
    /** Флаг что поля ввода не валидные */
    public fieldsNotValid: boolean = true;
    /** Флаг подтверждения данных формы */
    public submitted = false;
    /** Сообщение об ошибке */
    public errorMessage: string;

    /** Введённое значение логина */
    public loginValue;
    /** Введённое значение пароля */
    public passwordValue;
    /** Нажата кнопка просмотра пароля */
    public isShowPasswordPressed = false;

    /**
     * Конструктор
     * @param formBuilder Построитель элементов формы
     * @param router Модуль маршрутизации
     * @param authenticationService Сервис аутентификации пользователя
     */
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService) {
    }

    public ngOnInit() {
        // запрос полей для заполнения
        this.authenticationService.getFieldsForRegistration().subscribe(
            (data) => {
                data.forEach((field) => {
                    if (field.DataType == 'Date' && field.Value.length > 0) {
                        console.log(field);
                        field.Value = this.parseDate(field.Value).toLocaleDateString();
                    }
                });
                this.fields = data;
                this.isFieldsNotLoaded = false;
            }, (error) => {
                this.loading = false;
                this.errorMessage = error.error.ExceptionMessage ? error.error.ExceptionMessage : error.message;
            }
        );
    }
    /** Сменить тип поля ввода пароля */
    public changePasswordType(input: any) {
        input.type = input.type === 'password' ? 'text' : 'password';
        this.isShowPasswordPressed = !this.isShowPasswordPressed;
    }

    /**
     * Из utc в объект Date
     * @param dateUtc дата в формате utc
     * @returns {Date} объект даты
     */
    public parseDate(dateUtc: string): Date {
        if (dateUtc == null) {
            return null;
        }
        return new Date(dateUtc);
    }

    /**
     * Проверить поля ввода на валидность
     */
    public validateFields() {
        for (const field of this.fields) {
            if (field.IsRequired && !field.Value) {
                this.fieldsNotValid = true;
                return;
            }
        }

        this.fieldsNotValid = !this.passwordValue || !this.loginValue;
    }

    /** Обработчик нажатия кнопки входа */
    public onSubmit() {
        this.errorMessage = null;
        this.submitted = true;
        this.loading = true;

        this.authenticationService.registration(this.loginValue, this.passwordValue, this.fields).subscribe(
            (result) => {
                this.router.navigate(['/']);
            },
            (error) => {
                this.loading = false;
                this.errorMessage = error;
            });
    }

}
