/**
 * Утилиты
 */
export class UtilsService {
    /**
     * Получить текст ошибки из объекта с ошибкой
     * @param error объект ошибки
     * @returns Удобочитаемый текст ошибки
     */
    public static getErrorText(error: any) :string {
        if (error.error && error.error.ExceptionMessage) {
            return 'Произошла ошибка: ' + error.error.ExceptionMessage;
        }
        if (error.error && error.error.Message) {
            return 'Произошла ошибка: ' + error.error.Message;
        }

        if (error.Message) {
            return error.Message;
        }
       /* if (error.message) {
            return error.message;
        }*/

        return 'Произошла непредвиденная ошибка';
    }
}