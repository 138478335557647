import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IReport } from '../models/IReport';
import { IAccount } from '../models/IAccount';
import { catchError } from 'rxjs/operators';
import {IReportParam} from "../models/IReportParam";
import { IDebt } from '../models/IDebt';

/** Сервис для работы с отчетами */
@Injectable()
export class ReportService {

    /**
     * Базовый URL для API
     */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Клиент для работы с http
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl + 'api/report';
    }

    /**
     * Получение отчетов
     * @param reestrType Тип реестра
     */
    public getReports(): Observable<IReport[]> {
        return this.http.get<IReport[]>(this.apiUrl + '/getlist/ACCOUNTS');
    }

    /**
     * Получение отчетов
     * @param reestrType Тип реестра
     */
    public getPaymentReports(): Observable<IReport[]> {
        return this.http.get<IReport[]>(this.apiUrl + '/getlist/FINANCIALOPERATIONS');
    }

    /**
     * Получение отчета
     * @param report Отчет
     */
    public getReport(reportId: string, params: IReportParam[]): Observable<Blob> {
        return this.http.post(this.apiUrl + '/getreport/' + reportId, params, { responseType: 'blob' });
    }

    /**
     * Получение отчета акта
     * @param report Отчет
     */
    public getReviseActReport(reportiD: string, dateStart: string, dateEnd: string, accountId: string) {
        return this.http.get(this.apiUrl + '/getreviseact', {
            responseType: 'blob',
            params: { reportiD, dateStart, dateEnd, accountId }
        }).pipe(catchError(this.parseErrorBlob));
    }

    /**
     * Получение QR кода для оплаты
     * @param account Лицевой счет
     * @param sumToPay Сумма к оплате
     * @param budgetId Ид бюджета
     */
    public getPayQrCode(account: IAccount, sumToPay: number, budgetId: string) {
        return this.http.get(this.apiUrl + '/createPayQrCode', {
            responseType: 'blob',
            params: { accountId: account.Id, sumToPay: sumToPay.toString(), budgetId }
        }).pipe(catchError(this.parseErrorBlob));
    }

    /**
     * Получение QR кода для оплаты по начислению
     * @param opersToPay НАчисления для опалты
     * @param sumToPay Сумма к оплате
     */
    public getPayQrCodeByDebt(opersToPay: IDebt[], sumToPay: number) {
        return this.http.get(this.apiUrl + '/createPayQrCodeByDebt', {
            responseType: 'blob',
            params: { operationId: opersToPay[0].OperationId, sumToPay: sumToPay.toString() }
        }).pipe(catchError(this.parseErrorBlob));
    }

    /**
     * Получение минимальной даты акта сверки
     */
    public getMinReviseAct(): Observable<Date> {
        return this.http.get<Date>(this.apiUrl + '/getMinReviseAct');
    }

    /**
     * Парсинг блобовского поля ошибки
     * @param err Ошибка
     */
    parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();
    
        const obs = Observable.create((observer: any) => {
          reader.onloadend = (e) => {
            observer.error(JSON.parse(reader.result.toString()));
            observer.complete();
          }
        });
        reader.readAsText(err.error);
        return obs;
    }
}
