import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appInputMask]'
})
/**
 * Директива для масок
 */
export class InputMaskDirective {

    /** Маска */
    @Input('appInputMask') public appInputMask: string;

    /** Символы для автозаполнения */
    private autoCompleteChars: string[] = ['!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '+',
        '"', '№', ';', ':', '?', '_', '+',
        '<', '>', '.', '|'];

    /**
     * Конструктор
     * @param el Элемент
     * @param renderer Рендерер элемента
     */
    constructor(public el: ElementRef, public renderer: Renderer2) { }

    /** Обработчик нажатия кнопки */
    @HostListener('keyup', ['$event']) public onInput(e: KeyboardEvent) {
        let validResult = false;
        // Автозаполнение только если есть маска и не нжаты клавиши удаления текста
        if (this.appInputMask && (e.code !== 'Delete' && e.code !== 'Backspace')) {

            for (const char of this.autoCompleteChars) {
                const autoCompleteValue = (e.target as any).value + char;
                validResult = this.checkValid(autoCompleteValue);
                if (validResult) {
                    (e.target as any).value = autoCompleteValue;
                    break;
                }
            }
        }

        if (!validResult) {
            validResult = this.checkValid((e.target as any).value);
        }
        if (validResult) {
            this.renderer.setStyle(this.el.nativeElement, 'color', 'green');
        } else {
            this.renderer.setStyle(this.el.nativeElement, 'color', 'red');
        }
    }

    /** Обработчик потери фокуса */
    /* @HostListener('focusout', ['$event']) public focusout(e: FocusEvent) {
         const elementValue = (e.target as any).value;
         if (!elementValue || elementValue === '') {
             this.renderer.setStyle(this.el.nativeElement, 'color', 'black');
             return;
         }
         const testResult = this.checkValid(elementValue);
         if (!testResult) {
             this.el.nativeElement.focus();
         } else {
             this.renderer.setStyle(this.el.nativeElement, 'color', 'black');
         }
     }
     */

    /**
     * Проверка на соответсвие значения маске
     * @param value Значение
     */
    private checkValid(value: string) {
        const mask = this.appInputMask;
        if (!mask) {
            return true;
        }

        if (!mask.includes('|')) {
            const maskRegExp = '^' + mask + '$';
            const pattern = new RegExp(maskRegExp);
            const testResult = value.match(pattern);
            return testResult && testResult[0] === value;
        } else {
            const masks = mask.split('|');
            let result = false;

            for (const maskItem of masks) {
                const maskRegExp = '^' + maskItem + '$';
                const pattern = new RegExp(maskRegExp);
                const testResult = value.match(pattern);
                if (testResult && testResult[0] === value) {
                    result = true;
                    break;
                }
            }
            return result;
        }
    }
}
