import {Component} from '@angular/core';

/**
 * Элемент подсказки (tooltip) для ag-grid
 */
@Component({
    selector: 'ag-grid-custom-tooltip-component',
    template: ``,
})
export class AgGridCustomTooltip {
    private root: HTMLElement;
    init(params) {
        this.root = document.createElement('div');
        var color = params.color || '#4F4F4F';
        //var data = params.api.getRowNode(params.rowIndex).data;

        this.root.classList.add('custom-tooltip');
        this.root.style['background-color'] = color;
        this.root.style['position'] = 'absolute';
        this.root.style['padding'] = '10px';
        this.root.style['color'] = 'white';
        this.root.classList.add('bars-radius');
        this.root.innerHTML =
            '<span>' + params.value + "</span>";
    }

    /**
     * Возврат тела элемента
     * @returns {HTMLElement}
     */
    getGui(): HTMLElement {
        return this.root;
    }
}
