import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IAccount } from '../models/IAccount';
import { AccountInfoAttrEnum } from '../models/AccountInfoAttrEnum';
import { ITotalSum } from '../models/ITotalSum';
import { IDebt } from '../models/IDebt';

/** Сервис для работы с лицевыми счетами */
@Injectable()
export class AccountService {

    /**
     * Базовый URL для API
     */
    private apiUrl: string;

    /**
     * Конструктор
     * @param http Клиент для работы с http
     */
    constructor(private http: HttpClient) {
        this.apiUrl = environment.apiUrl + 'api/account';
    }

    /**
     * Получение ЛС
     * @param infoAcc Информация по полям которые необходимо получить 
     */
    public get(infoAcc: AccountInfoAttrEnum): Observable<IAccount[]> {
        return this.http.get<IAccount[]>(this.apiUrl, {
            params: { info: infoAcc.toString() }
        });
    }

    /**
     * Получение произвольной информации по ЛС
     * @param infoAcc Информация по полям которые необходимо получить
     */
    public getById(accountId: string, infoAcc: AccountInfoAttrEnum): Observable<IAccount> {
        return this.http.get<IAccount>(this.apiUrl +"/GetByAccId", {
            params: { accountId, info: infoAcc.toString() }
        });
    }

    
    /**
     * Получение не оплаченных начислений
     * @param accountId Идентификатор лицевого счета
     */
    public getDebtForPay(accountId: string): Observable<IDebt[]> {
        return this.http.get<IDebt[]>(this.apiUrl +"/getDebtForPay", {
            params: { accountId }
        });
    }
    
    /**
     * Получение ЛС
     * @param infoAcc Информация по полям которые необходимо получить 
     * @param dogTypeId Тип договора
     * @param page Страница которую необходимо получить
     * @param countOnPage Количество ЛС на странице
     */
    public getByDogType(infoAcc: AccountInfoAttrEnum, dogTypeId: string, page: number, countOnPage: number): Observable<IAccount[]> {
        return this.http.get<IAccount[]>(this.apiUrl, {
            params: {
                info: infoAcc.toString(),
                typeId: dogTypeId,
                page: page.toString(),
                countOnPage: countOnPage.toString()
            }
        });
    }


    /**
     * Получение количества лицевых счетов
     * @param dogTypeId Тип договора
     */
    public getCount(typeId: string): Observable<number> {
        return this.http.get<number>(this.apiUrl + "/getcount", {
            params: {
                typeId
            }
        });
    }

    /**
     * Получение общих сумм
     * @param typeId Тип договора
     */
    public getTotalSum(typeId: string): Observable<ITotalSum> {
        return this.http.get<ITotalSum>(this.apiUrl + "/getsumm", {
            params: {
                typeId
            }
        });
    }

    /**
     * Получение лицевых счетов по догоовру
     * @param dogId Идентификатор договора
     */
    public getAccountListByDog(dogId: string) {
        let infoAcc = AccountInfoAttrEnum.AllDebt | AccountInfoAttrEnum.Budgets | AccountInfoAttrEnum.LastPay | AccountInfoAttrEnum.NextPay;
        return this.http.get<IAccount[]>(this.apiUrl, {
            params: {
                dogId: dogId,
                info: infoAcc.toString()
            }
        });
    }
}
