import { ConfigService } from './../../services/config.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChildren, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import {MatSelectChange} from '@angular/material/select';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import {AccountInfoAttrEnum} from '../../models/AccountInfoAttrEnum';
import { IAccount } from '../../models/IAccount';
import {IBudget} from '../../models/IBudget';
import {IReport} from '../../models/IReport';
import {AccountService} from '../../services/account.service';
import { ReportService } from '../../services/report.service';
import {TenantService} from '../../services/tenant.service';
import {UtilsService} from '../../services/utils.service';

import * as FileSaver from 'file-saver';
import { IDebt } from '../../models/IDebt';
import {IReportParam} from '../../models/IReportParam';
import { PayService } from '../../services/pay.service';
import { PayByCardAttrReqComponent } from '../pay-by-card-attr-req/pay-by-card-attr-req.component';

@Component({
    selector: 'app-pay',
    templateUrl: 'pay.component.html',
    styleUrls: ['./pay.component.css']
})
/** Контроллер блока информации о мобильной версии */
export class PayComponent implements OnInit {

    @ViewChildren('id1') checkboxes;

    /** Лицевой счет для которого формируется оплата */
    public account: IAccount = {} as IAccount;
    /** Сумма к оплате */
    public sumToPay: number = 0;
    /** Картинка с qr кодом */
    public qrImage: any;
    /** Последняя ошибка с сервера */
    public error: string;

    /** Режим выбора оплаты, селект раскрыт */
    public customSelectOpen: boolean = false;

    /** Список бюджетов */
    public budgetList: IBudget[];

    public opersToPay: IDebt[] = [];

    /** Текущий бюджет, по которому пользователь хочет платить */
    public currentBudgetId: string;

    /** Список доступных платежных квитанций */
    public paymentReports: IReport[];
    public debts: IDebt[];
    public debtsToView: IDebt[];

    /** Есть ли возможность оплатить по карте **/
    public isPayWithCard: boolean;

    // Переменные для хранения конфигурации
    private isDisabledGenerateReceiptsForSeveralAccruals = false;

    private tenantId: string;

    /**
     * Конструктор
     * @param data Данные переданные в диалоговое окно
     * @param _sanitizer Для создания картинку для отбражения
     * @param reportService Сервис работы с отчетами
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _sanitizer: DomSanitizer,
        protected accountService: AccountService,
        protected reportService: ReportService,
        protected tenantService: TenantService,
        protected payService: PayService,
        private dialog: MatDialog,
        private configService: ConfigService,
        private dialogRef: MatDialogRef<PayComponent>
    ) { }

    /** Действия выполняемые при создании объекта,
     * инициализация полей формы и адреса перехода
     */
    public ngOnInit() {
        this.configService.getConfig().subscribe((res: any) => {
            this.isDisabledGenerateReceiptsForSeveralAccruals = res.isDisabledGenerateReceiptsForSeveralAccruals;
        })
        this.account = this.data.account;
        this.payService.createPayByCardCanExecute().subscribe(
            (isCanPay: boolean) => {
                this.isPayWithCard = isCanPay
            }
        );
        this.accountService.getDebtForPay(this.data.account.Id).subscribe(
            (debts) => {
                this.debts = debts;
                this.debtsToView = debts;
                this.error = null;
            },
            (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
            });

        this.reportService.getPaymentReports().subscribe(
            (reports) => {
                this.paymentReports = reports;
            }, (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
            }
        );

        this.tenantService.get().subscribe(
            (tenant) => {
                this.tenantId = tenant.Id;
            }, (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
            }
        );
    }

    /**
     * Клик по селекту выбора платежа
     */
    public clickCustomSelect() {
        this.customSelectOpen = !this.customSelectOpen;

        if (!this.customSelectOpen) {
            if (this.sumToPay > 0.01) {
                this.reportService.getPayQrCodeByDebt(this.opersToPay, this.sumToPay).subscribe(
                    (qrImage) => {
                        const objectURL = URL.createObjectURL(qrImage);
                        this.qrImage = this._sanitizer.bypassSecurityTrustUrl(objectURL);
                        this.error = null;
                    },
                    (error) => {
                        console.log(error);
                        this.error = UtilsService.getErrorText(error);
                    });
            } else {
                this.qrImage = null;
            }
        }
    }

    /**
     * Клик по чекбоксу с платежом
     * @param budgetId Ид бюджета
     * @param sum Сумма платежа
     * @param isAdd true если добавить к общей сумме, false если снять выбор и удалить из общей суммы
     * @param el элемент на котором сработало событие
     */
    public clickAddDebt(debt: IDebt, sum: number, isAdd: boolean, el: HTMLInputElement) {
        if (debt.PaymentDate) {
            return;
        }

        const isDisabledForSeveral = this.isDisabledGenerateReceiptsForSeveralAccruals;

        if(isDisabledForSeveral) {
            this.checkboxes.map(el => {
                el.nativeElement.checked = false;
            })

            if(isAdd)
                el.checked = true;
        }

        if (isAdd && !isDisabledForSeveral) {
            if (this.opersToPay.indexOf(debt) === -1) {
                this.opersToPay.push(debt);
            }
            this.debtsToView = this.debts.filter(x => x.IsPeni === debt.IsPeni);
            this.sumToPay += sum;
        } else if(!isDisabledForSeveral) {
            const indexDebt = this.opersToPay.indexOf(debt);
            if (indexDebt !== -1) {
                this.opersToPay.splice(indexDebt, 1);
            }
            if (this.opersToPay.length == 0){
                this.debtsToView = this.debts;
            }
            this.sumToPay -= sum;
        } else if(isAdd && isDisabledForSeveral) {
            if (this.opersToPay.indexOf(debt) === -1) {
                this.opersToPay = [debt];
            }
            this.sumToPay = sum;
        } else {
            const indexDebt = this.opersToPay.indexOf(debt);
            if (indexDebt !== -1) {
                this.opersToPay = [];
            }

            this.sumToPay = 0;
        }
    }

    /** Закрытие окна */
    public close() {
        this.dialogRef.close();
    }

    /**
     * Выбор отчёта для печати
     * @param event
     */
    public printReport(event: MatSelectChange) {
        event.source.writeValue('1');   // В select значение не меняется после выбора
        const report: IReport = this.paymentReports.find((x) => x.Id === event.value);
        if (! report) {
            return;
        }

        let operationIdParam: IReportParam = report.Parameters.find((x) => x.ParamName === 'OperationId');

        if (! operationIdParam) {
            operationIdParam = {
                ParamName: 'OperationId',
                ParamType: 'String'
            } as IReportParam

            report.Parameters.push(operationIdParam);
        }

        operationIdParam.ParamValue = this.opersToPay[0].OperationId;

        this.reportService.getReport(report.Id, report.Parameters).subscribe(
            (response) => {
                FileSaver.saveAs(response, report.Name + '.pdf');
            });
    }

    /**
     * Оплата по карте
     */
    public payByCard(): void {
        this.payService.createPayByCard(this.opersToPay).subscribe(
            (url) => {
                if (url !== 'error') {
                    window.open(url);
                }
            },
            (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
            });
    }

    /**
     * Обработать полученные бюджеты
     */
    private handleBudgets() {
        this.budgetList = [];
        this.budgetList.push(this.account.BudgetObjaz);
        if (this.budgetList.every((x) => x.Id !== this.account.BudgetFine.Id)) {
            this.budgetList.push(this.account.BudgetFine);
        }
        if (this.budgetList.every((x) => x.Id !== this.account.BudgetPeni.Id)) {
            this.budgetList.push(this.account.BudgetPeni);
        }
    }
}
