import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/** Перехватчик запросов */
@Injectable()
export class JwtInterceptorService implements HttpInterceptor {
    /**
     * Перехват запроса
     * @param request Запрос
     * @param next Следующий обработчик
     */
    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const localStorageToken = localStorage.getItem('token');
        const sessionStorageToken = sessionStorage.getItem('token');
        const token = localStorageToken !== null ? localStorageToken : sessionStorageToken;
        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }
        return next.handle(request);
    }
}
