import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ICalcDoc } from '../../models/ICalcDoc';
import { CalcDocService } from '../../services/calc-doc.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-list-documents',
    templateUrl: 'list-documents.component.html',
    styleUrls: ['./list-documents.component.css']
})
/** Контроллер списка договоров */
export class ListDocumentsComponent implements OnInit, OnChanges {

    /** Идентификиатор договора */
    public dogId: string;

    /** Список ЛС для отображения */
    public tableAcc: ICalcDoc[];

    /** Выбранная строка */
    public selectedRow: ICalcDoc;

    /** Последняя ошибка с сервера */
    public error: string;

    constructor(
        protected route: ActivatedRoute,
        private calcDocService: CalcDocService) {
    }

    public ngOnInit(): void {
        this.dogId = this.route.snapshot.queryParams.dogId;
        this.calcDocService.get(this.dogId, true).subscribe(
            (calcDocs) => {
                this.tableAcc = calcDocs.sort((a, b) =>
                    new Date(b.DateStart).getTime() - new Date(a.DateStart).getTime());
                this.error = null;
            },
            (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
            }
        );
    }

    // tslint:disable-next-line:no-empty
    public ngOnChanges(changes: SimpleChanges): void {}

    /** Событие выбора строки таблицы */
    public setClickedRow = function(item: ICalcDoc) {
        this.selectedRow = item;
    };
}
