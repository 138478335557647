import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { PayService } from '../../services/pay.service';
import { UtilsService } from '../../services/utils.service';

@Component({
    selector: 'app-pay-wait',
    templateUrl: 'pay-wait.component.html',
    styleUrls: ['./pay-wait.component.css']
})
/** Контроллер блока ожидания проведения оплаты */
export class PayWaitComponent implements OnInit {

    /** Последняя ошибка с сервера */
    public error: string;

    /** Текущий статус */
    public status: string = "waitReq";

    /** Время сколько мы будем ожидать ответа */
    private timeLeft: number = 40;

    /** Интервал ожидания */
    private interval;

    /**
     * Конструктор
     * @param data Данные переданные в диалоговое окно
     * @param payService Сервис платежей
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        protected payService: PayService) { }

    /** Действия выполняемые при создании объекта,
     * инициализация полей формы и адреса перехода
     */
    public ngOnInit() {
        this.startTimer();
        this.orderinfoWait();
    }

    /** Ожидание опалты */
    private orderinfoWait() {
        this.payService.getStatusByOrderId(this.data.orderId).subscribe(
            (status) => {
                (async () => {
                    this.status = status;
                    if (status == "UrlCreated" && this.timeLeft > 0) {
                        await this.delay(500);
                        this.orderinfoWait();
                    } else {
                        if (status == "UrlCreated" ) {
                            this.status = "Error"
                        }
                        clearInterval(this.interval);
                    }
                })();
            }, (error) => {
                console.log(error);
                this.error = UtilsService.getErrorText(error);
            }
        );

    }

    /** Задержка между запросами */
    private delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /** Запуск таймера ожидания ответа */
    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
            } else {
                clearInterval(this.interval);
            }
        }, 1000)
    }
}
