/** Перечисление флагов, с информацией по полям которые необходимо получать у лицевого счета */
export enum AccountInfoAttrEnum {
    /** Никаких доп полей */
    None,
    /** Пока не используется */
    DebtPay = 1,
    /** Последняя оплата */
    LastPay = 1 << 1,
    /** Очередной платеж */
    NextPay = 1 << 2,
    /** Задолженность */
    Debt = 1 << 3,
    /** Все задолженности */
    AllDebt = 1 << 4,
    /** Бюджеты */
    Budgets = 1 << 5,
}